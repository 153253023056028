.quantity-buy-wrapper{
	        display: flex;
    align-items: flex-end;
    justify-content: space-between;
    column-gap: 5%;
}

.quantity-buy-wrapper .product-block.cstmqty{
	    width: 100%;
    max-width: fit-content;
}
.quantity-buy-wrapper .product-block{
	margin-bottom: 0px;
	width: 100%;
	max-width: 100%;
}
.quantity-buy-wrapper .product-block.cstmatc form+div{
	display: none;
}
.custom-content.cstmpaymemticons{
	    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
}
.custom-content.cstmpaymemticons .footer__section {
    margin-top: 0px;
}
.custom-content.cstmpaymemticons .payment-icons li{
	display: inline-flex;
}